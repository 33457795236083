import React, { useState } from 'react';

function ContactForm () {

  const [data, setData] = useState({ "form-id": "contact-form" });

  const setFormData = (key, value) => {
    const current = data;
    current[key] = value;
    setData(current);
  }

  const onSubmit = () => {
    console.log("data");
    console.log(data);
    // TODO : send to send in blue or other marketing platform
  }


  return (
      <form className="mx-auto md:w-1/2">
        <p className="mb-8 leading-loose">
          Leave us a message filling in form below or simply send us an email {` `}
          <a
            className="font-bold text-gray-700 no-underline"
            href="mailto:info@ataks.cz"
            target="_blank"
            rel="noopener noreferrer"
          >
            info@ataks.cz
            </a>
            .
          </p>

        <label
          className="block mb-2 text-xs font-bold uppercase"
          htmlFor="first-name"
        >
          First Name
          </label>

        <input
          className="w-full mb-6 form-input"
          id="first-name"
          placeholder="Bill"
          type="text"
          onChange={(e) => { setFormData("first-name", e.target.value) }}
        />

        <label
          className="block mb-2 text-xs font-bold uppercase"
          htmlFor="last-name"
        >
          Last Name
          </label>

        <input
          className="w-full mb-6 form-input"
          id="last-name"
          placeholder="Murray"
          type="text"
          onChange={(e) => { setFormData("last-name", e.target.value) }}
        />

        <label
          className="block mb-2 text-xs font-bold uppercase"
          htmlFor="email"
        >
          Email
          </label>

        <input
          className="w-full mb-6 form-input"
          id="email"
          placeholder="bill.murray@company.com"
          type="email"
          onChange={(e) => { setFormData("email", e.target.value) }}
        />

        <label
          className="block mb-2 text-xs font-bold uppercase"
          htmlFor="message"
        >
          Message
          </label>

        <textarea
          className="w-full mb-6 form-textarea"
          id="message"
          placeholder="Say something..."
          rows="8"
          onChange={(e) => { setFormData("message", e.target.value) }}
        />

        <button className="px-4 py-2 text-sm font-bold text-white bg-gray-700 border-b-4 border-gray-800 rounded hover:border-gray-700 hover:bg-gray-600"
          onClick={onSubmit}
        >
          Submit
          </button>
      </form>
  );
}

export default ContactForm;

import React, { useState } from 'react';
import LayoutPage from "../components/layout-page";
import SEO from "../components/seo";
import ContactForm from "../components/contact-form";

import { defaultKeywords } from "./seo.json"
import SectionMain from '../components/section-main';

const ContactPage = () => {  
  return (
    <LayoutPage>
      <SEO
        keywords={[ ...defaultKeywords, `contact`, `email`, `message`]}
        title="Contact"
      />
      <SectionMain>
        <ContactForm></ContactForm>
      </SectionMain>
    </LayoutPage>
  );
}

export default ContactPage;
